import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FirebaseFirestore } from '@capacitor-firebase/firestore';

// Services
import { useAuthContext } from '../auth.provider';
import {
  IOnboardingData,
  hasFatalError,
  hydrateOnboardingData,
  setInitialized,
  setLoading,
} from './onboarding.actions';
import { initialState, onboardingReducer } from './onboarding.reducer';
import { useUserContext, useUserDispatch } from '../user/user.provider';
import { setUser, setLoading as setUserLoading } from '../user/user.actions';
import { getActiveOnboardingStep } from './onboarding.utils';
import { captureException } from '../../analytics/sentry';
import {
  createUser,
  getUser,
  updateIdentity,
} from '../../services/user.service';
import { identifyUser } from '../user/user.utils';
import { ROUTE_PATHS } from '../../components/_pages/_utils/router.utils';
import { UnauthorizedError } from '../../services/error-types';
import { Capacitor } from '@capacitor/core';
import { getViewportType } from '../../hooks/useWindowSize';

const OnboardingContext = React.createContext(null);
const OnboardingDispatchContext = React.createContext(null);

export const OnboardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(onboardingReducer, initialState);
  const [isCreating, setCreating] = useState(false);

  const {
    firebaseUser,
    initialized: authInitialized,
    loading: authLoading,
  } = useAuthContext();
  const {
    user,
    loading: userLoading,
    initialized: userInitialized,
  } = useUserContext();
  const userDispatch = useUserDispatch();

  const authReady = !authLoading && authInitialized;
  const userReady = !userLoading && userInitialized;
  const isReady = authReady && userReady;

  useEffect(() => {
    console.log('Checking whether to hydrate onboarding data...', isReady);
    if (isReady) {
      const fetchUserData = async () => {
        try {
          console.log('Hydrating...');
          const userDoc = await FirebaseFirestore.getDocument({
            reference: `users/${firebaseUser?.uid}`,
          });
          const nextState = userDoc.snapshot.data as IOnboardingData;

          console.log('Next state', nextState);
          if (nextState) {
            dispatch(
              hydrateOnboardingData({
                firebaseRef: firebaseUser?.uid,
                ...nextState,
              })
            );
          }
        } catch (err) {
          console.error('FireStore hydration failed', err);
          if (!err?.message?.includes('Missing or insufficient permissions')) {
            captureException(err);
          }
        }

        console.debug('Onboarding Provider - Initialized');

        dispatch(setInitialized(true));
        dispatch(setLoading(false));
      };

      if (firebaseUser && firebaseUser.uid !== state.firebaseRef) {
        dispatch(setLoading(true));

        fetchUserData();
      } else {
        dispatch(setLoading(false));
        dispatch(setInitialized(true));
      }
    }
  }, [firebaseUser, isReady, state.firebaseRef]);

  useEffect(() => {
    if (
      !state.hasFatalError &&
      isReady &&
      state.initialized &&
      !user &&
      firebaseUser &&
      !firebaseUser?.isAnonymous &&
      getActiveOnboardingStep(state) === ROUTE_PATHS.REGISTER &&
      !isCreating
    ) {
      userDispatch(setUserLoading(true));
      const handleCreateUser = async () => {
        setCreating(true);

        try {
          await FirebaseAuthentication.getCurrentUser();

          await createUser(
            state,
            (
              await FirebaseAuthentication.getIdToken()
            ).token
          );

          const user = await getUser(
            firebaseUser.uid,
            (
              await FirebaseAuthentication.getIdToken()
            ).token
          );

          if (user) {
            updateIdentity({
              goal: user.goal || state.goal,
              platform: Capacitor.getPlatform(),
              viewport: getViewportType(),
            });
            identifyUser(firebaseUser, user);
            userDispatch(setUser(user));
          }
        } catch (err) {
          captureException(err);

          if (err instanceof UnauthorizedError) {
            dispatch(hasFatalError());
          }
        }

        setCreating(false);
        userDispatch(setUserLoading(false));
      };

      handleCreateUser();
    }
  }, [state, user, firebaseUser?.isAnonymous, isReady, isCreating]);

  console.debug('Onboarding Context', state);

  return (
    <OnboardingContext.Provider value={state}>
      <OnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardingDispatchContext.Provider>
    </OnboardingContext.Provider>
  );
};

export const useOnboardingDispatch = () => {
  return useContext(OnboardingDispatchContext);
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};
