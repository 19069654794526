import React from 'react';
import styled from 'styled-components';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
} from '@mui/material';
import { getImage } from '../utils';

const testimonials = [
  [
    [
      {
        body: 'As a first gen college student, no one taught me about credit cards. Also, the racking up of fees were most confusing starting out. Visualization of my finances makes it easier to understand where to start in terms of fixing my score and getting out of debt.',
        index: 1,
        author: {
          name: 'Solve User',
        },
      },
    ],
    [
      {
        body: "Visualization of my finances makes it easier to understand where to start in terms of  fixing my score and getting out of debt.",
        index: 2,
        author: {
          name: 'Solve User',
        },
      },
    ],
  ],
  [
    [
      {
        body: 'I would recommend it to an average person that is intimidated by math, if you are looking for something incredibly simple.',
        index: 3,
        author: {
          name: 'Solve User',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: "The home buying process is daunting. I was given a long list of different loan options from my current bank, but I didn't know which made the most sense for my situation when buying my condo. Solve's home finance tools made it easy. I answered a few questions on the property and how long I planned to own it, and they provided me with the best lender and loan option. I saved about $2,000, especially on fees.",
        index: 4,
        author: {
          name: 'Solve User',
        },
      },
      // More testimonials...
    ],
  ],
];

interface ITestimonialSection {
  className?: string;
  pb?: number;
}

const TestimonialSection: React.FC<ITestimonialSection> = ({ className, pb }) => {
  return (
    <Box position="relative" bgcolor="white" pb={pb || 8} pt={6} px={4} style={{ border: '1px solid #ddd', borderBottom: 'none' }}>
      <Box textAlign="center" mb={4}>
        <h6 style={{ fontSize: '24px', fontFamily: 'Majorant-Regular', margin: 0 }}>We have helped thousands of amazing people</h6>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((columnGroup, columnGroupIdx) => (
          <Grid item xs={12} md={6} xl={4} key={columnGroupIdx}>
            {columnGroup.map((column, columnIdx) => (
              <Grid container direction="column" key={columnIdx} spacing={4}>
                {column.map((testimonial) => (
                  <Grid item key={testimonial.author.name}>
                    <Box borderRadius="16px">
                      <CardContent style={{ boxShadow: 'none' }}>
                        <Typography variant="h6" color="textPrimary" gutterBottom fontFamily="Majorant-Light" fontSize="16px">
                          {`“${testimonial.body}”`}
                        </Typography>
                        <Divider />
                        <Box display="flex" alignItems="center" mt={1}>
                          <Avatar
                            src={getImage(`AVATAR_${(testimonial.index % 6)}`)}
                          />
                          <Box ml={2}>
                            <Typography variant="body1" fontFamily="Majorant-Regular">
                              {testimonial.author.name}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default TestimonialSection;
